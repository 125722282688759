<template>
  <v-card
    light
    v-if="ad"
    height="100%"
    max-height="100%"
    :class="`flexcard ${ad.class || 'white--text'}`"
    :flat="flat"
  >
    <v-card-text class="grow">
      <v-row dense>
        <v-col cols="12">
          <v-img
            :key="img"
            height="100%"
            max-height="100px"
            :src="img"
            :contain="ad.contain"
            :class="ad.class || 'white--text'"
            :position="ad.position"
            class="mb-3"
          >
          </v-img>
          <div v-for="(d, i) in ad.divs" :key="`${ad.id}-${i}`" v-html="d.html" :class="d.class" class="py-3"></div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="px-5">
        <v-col cols="12" sm="6">
          <v-btn color="color3 color3Text--text" icon text @click.stop="actionClick(ad.instagram)" v-if="ad.instagram">
            <v-icon>fab fa-instagram</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" icon text @click.stop="actionClick(ad.facebook)" v-if="ad.facebook">
            <v-icon>fab fa-facebook-f</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" icon text @click.stop="actionClick(ad.tiktok)"  v-if="ad.tiktok">
            <v-icon>fab fa-tiktok</v-icon>
          </v-btn>
          <v-btn color="color3 color3Text--text" icon text @click.stop="actionClick(ad.twitter)" v-if="ad.twitter">
            <v-icon>fab fa-twitter</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-right" cols="12" sm="6">
          <v-btn color="color3 color3Text--text" @click.stop="actionClick(ad.actionUrl)">
            {{ad.actionText}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import Shuffle from '@/helpers/Shuffle'
// import FitText from '@/components/Utils/FitText'

export default {
  props: ['ad', 'flat'],
  data () {
    return {
      count: 0,
      interval: null,
      img: null
    }
  },
  computed: {
    ...mapGetters(['liveUpdates']),
    color () {
      if (this.img && this.img.includes('color=')) {
        const q = (this.img.split('?'))[1]
        var query = {}
        var pairs = (q[0] === '?' ? q.substr(1) : q).split('&')
        for (var i = 0; i < pairs.length; i++) {
          var pair = pairs[i].split('=')
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
        }
        return query.color || this.ad.color
      }
      return this.ad.color
    }
  },
  methods: {
    actionClick: debounce(function (url) {
      this.doActionClick(url)
    }, 333),
    doActionClick (url) {
      console.log(url)
      const dto = {
        id: this.ad.id,
        page: `${this.$route.fullPath} - ${url}`
      }
      this.liveUpdates.invoke('AdClick', dto)
      this.ad.relative ? this.$router.push(url) : window.open(url, '_blank')
    },
    shuffleMe () {
      const images = Shuffle(this.ad.images)
      this.img = images[0]
    },
    onStart (v) {
      if (this.interval) clearInterval(this.interval)
      if (!v) return
      this.interval = setInterval(() => {
        this.count++
      }, 13000)
    }
  },
  watch: {
    count: 'shuffleMe'
  },
  mounted () {
    this.shuffleMe()
    this.onStart(true)
  },
  destroyed () {
    this.onStart(false)
  }
}
</script>

<style>

</style>
