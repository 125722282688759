<template>
  <v-card flat>
    <v-container fluid class="pa-0">
      <v-row dense>
        <v-col cols="12" sm="6" lg="4" v-for="ad in ads" :key="ad.id" class="pa-sm-5">
          <full-ad-card :ad="ad" style="z-index: 10000"></full-ad-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { firstBy } from 'thenby'
import FullAdCard from './FullAdCard'

export default {
  props: ['adTag'],
  data () {
    return {
      ads: []
    }
  },
  methods: {
    getAds () {
      this.$http.get(`/ads?username=${this.adTag}`)
        .then(r => {
          this.ads = r.data
          this.ads.sort(firstBy('spot'))
        })
    }
  },
  components: {
    FullAdCard
  },
  mounted () {
    this.getAds()
  }
}
</script>
