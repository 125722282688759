<template>
  <v-card flat>
    <v-img
      src="https://vblimages.blob.core.windows.net/images/c51791c5-beae-4d8e-b210-1b04c4b3d742.webp"
      height="500px"
    >
      <div class="text-h4 text-sm-h2 text-lg-h1 fill-height d-flex align-center text-center justify-center neonText" style="width: 100%;">
        Volleyball Life Holiday Deals!
      </div>
    </v-img>
    <v-card-text>
      <v-card style="z-index:4">
        <v-card-text>
          <p class="text-center font-weight-bold text-subtitle-1 white--text">
            We cannot thank you enough for enjoying our software and viewing it over 15M times in 2022!   We are a volleyball company first.  Every employee, intern, partner at Volleyball Life is or has been a volleyball athlete so for this Holiday season we want to send out a big thank you!</p>
          <p class="text-center">
            We selected volleyball companies, negotiated discounts, and we gift wrapped some amazing deals to bring to your community.
            If you are in need for volley gifts, or something for yourself, consider buying through this campaign. It’s rare in this world to get a Win, Win, Win scenario.  You will receive discounts you cannot find elsewhere (Win 1), Volleyball Life will get a small commission (Win 2) and these volley companies will get to put their hard work into the hands of their supporters!!! (Win 3)
          </p>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-text class="pa-0 pt-3">
          <ad-page adTag="tag-holidays"></ad-page>
        </v-card-text>
      </v-card>
    </v-card-text>
    <let-it-snow
      v-bind="flareConf"
      :show="show"
    ></let-it-snow>
    <let-it-snow
      v-bind="snowConf"
      :show="show"
    ></let-it-snow>
  </v-card>
</template>

<script>
import AdPage from '@/components/Ads/AdPage.vue'
import LetItSnow from '@/components/Utils/Borrowed/LetItSnow'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      snowConf: {
        windPower: 1,
        speed: 3,
        count: 250,
        size: 0
      },
      flareConf: {
        windPower: 0,
        speed: 0,
        color: '#392F52',
        size: 120,
        opacity: 0.00000001,
        count: 20,
        interaction: false
      },
      show: false
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  components: {
    AdPage,
    LetItSnow
  },
  mounted () {
    this.$store.commit('SET_THEME_DARK', true)
    this.$store.commit('SET_THEME_DRAWER', false)
    this.show = true
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('SET_THEME_DARK', false)
    this.$store.commit('SET_THEME_DRAWER', true)
    next()
  }
}
</script>

<style scoped>
.neonText {
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}
</style>
